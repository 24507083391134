.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, 300px);
    grid-gap: 2rem;
    justify-content: center;
}

@media (max-width: 786px) {
    .gallery {
        grid-template-columns: 1fr;
        justify-items: center;
    }
}
